.fiba-browse-wrap {
  transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease, box-shadow 0.2s ease;
  color: #495057;
  background-color: #fff;
  border-color: #ced4da;
  outline: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  border-radius: 0.25rem;
  padding: 0;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  position: relative;
  transition: all 0.1s ease;
  cursor: default;
  outline: 0;

  input {
    padding: $kendo-input-padding-y $kendo-input-padding-x;
    width: 100%;
    box-sizing: border-box;
    border: 0;
    outline: 0;
    color: inherit;
    background: none;
    font: inherit;
    flex: 0 1 auto;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease, box-shadow 0.2s ease;
  }

  span {
    padding: 0;
    width: calc(1.42857em + 8px);
    border-width: 0;
    border-inline-start-width: 0px;
    border-style: solid;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    flex: 0 0 auto;
    text-align: center;
    cursor: pointer;
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #f5f5f5;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
  }
  // span {
  //   border-radius: 0 0.25rem 0.25rem 0;
  //   padding: 0.19rem;
  //   border-width: 0 0 0 1px;
  //   box-sizing: border-box;
  //   border-style: solid;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   flex: 0 0 auto;
  //   text-align: center;
  //   cursor: pointer;
  //   font-size: 16px;
  //   color: #212529;
  //   background-color: #dee2e6;
  //   border-color: #dee2e6;
  // }
}
